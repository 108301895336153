/* eslint-disable no-unused-vars */
// Dependencies
import React from 'react';
import { HashRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
// Router
import Routes from './router/routes';
// Components
import Nav from './master/Nav';
import ScrollToTop from './master/ScrollToTop';
// StyleSheets
import './stylesheets/master.scss';

class App extends React.Component {
  componentDidMount() {
    ReactGA.initialize('UA-139550267-1');
    ReactGA.pageview('/home');
  }

  render() {
    return (
      <HashRouter basename="/">
        <ScrollToTop>
          <Nav />
          <Routes />
        </ScrollToTop>
      </HashRouter>
    );
  }
}

export default App;
